<template>
  <div>
    <header id="affirm-header">
      <div class="st-container">
        <nav class="navbar navbar-expand-lg navbar-light py-4 --text-dark">
          <div class="container-fluid px-0">
            <router-link to="/" class="logo">
              <img alt="Logo" :src="logo" />
            </router-link>
            <div class="search-box">
              <button value="Go" @click='search_terms' type="submit" class="btn-search no-border"
                ><i class="fa fa-search i-19"></i
              ></button>
                <input
                  class="input-search"
                  type="text"
                  placeholder="Search..."
                  aria-label="Search"
                  name="search"
                  v-model='search_query'
                  @click='openDropdown'
                  @keydown.enter="search_terms"
                  />
            </div>
            <div class="collapse navbar-collapse" id="">
              <header-menu></header-menu>
              <SearchBar></SearchBar>
            </div>
            <div class="afrim-menu">
              <div class="position-fixed toggle-menu-icon">
                <i
                  class="fa fa-bars ms-4"
                  aria-hidden="true"
                  @click="showSidebar"
                  style="font-size: 23px !important"
                >
                </i>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
    <vue-over-body
      :dim="dim"
      :open="sidebar"
      before="sideBefore"
      after="sideAfter"
    >
      <div
        class="main-sidebar"
        style="padding: 10px; background-color: #f8f8f8; min-height: 100%"
      >
        <aside class="menu">
          <div class="minus-icon" @click="sidebar = 0">
            <div class="minus-shape"></div>
          </div>
        </aside>
        <div class="pt-5 ps-5 side-nav">
          <div class="w-100">
            <div class="overlay-text-lg mb-3">
              <router-link to="/" class="text-dark text-decoration-none">
                <div class="d-flex">
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt="Group 55"
                    class="arrow"
                    width="25px"
                  />
                  <h4 class="s-h4 mb-0 --text-dark">Home</h4>
                </div>
              </router-link>
            </div>
            <div class="overlay-text-lg mb-3">
              <router-link to="/books/" class="text-dark text-decoration-none">
                <div class="d-flex">
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt="Group 55"
                    class="arrow"
                    width="25px"
                  />
                  <h4 class="s-h4 mb-0 --text-dark">Books</h4>
                </div>
              </router-link>
            </div>
            <div class="overlay-text-lg mb-3">
              <router-link
                to="/authors/"
                class="text-dark text-decoration-none"
              >
                <div class="d-flex">
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt="Group 55"
                    class="arrow"
                    width="25px"
                  />
                  <h4 class="s-h4 mb-0 --text-dark">Authors</h4>
                </div>
              </router-link>
            </div>
            <div class="overlay-text-lg mb-3">
              <router-link to="/about/" class="text-dark text-decoration-none">
                <div class="d-flex">
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt="Group 55"
                    class="arrow"
                    width="25px"
                  />
                  <h4 class="s-h4 mb-0 --text-dark">About</h4>
                </div>
              </router-link>
            </div>
            <div class="overlay-text-lg mb-3">
              <router-link to="/news" class="text-dark text-decoration-none">
                <div class="d-flex">
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt="Group 55"
                    class="arrow"
                    width="25px"
                  />
                  <h4 class="s-h4 text-nowrap mb-0 --text-dark">
                    News & features
                  </h4>
                </div>
              </router-link>
            </div>
            <!-- <div class="overlay-text-lg mb-3">
              <router-link to="/events/" class="text-dark text-decoration-none">
                <div class="d-flex">
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt="Group 55"
                    class="arrow"
                    width="25px"
                  />
                  <h4 class="s-h4 mb-0 --text-dark">Events</h4>
                </div>
              </router-link>
            </div> -->
            <div class="overlay-text-lg">
              <router-link
                to="/submissions"
                class="text-dark text-decoration-none"
              >
                <div class="d-flex">
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt="Group 55"
                    class="arrow"
                    width="25px"
                  />
                  <h4 class="s-h4 mb-0 --text-dark">Submissions</h4>
                </div>
              </router-link>
            </div>
          </div>
          <div class="mt-5">
            <div class="overlay-text d-flex align-items-center mb-3">
              <router-link
                to="/catalogues"
                class="text-dark text-decoration-none"
              >
                <div class="d-flex">
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt="Group 55"
                    class="arrow"
                  />
                  <h6 class="s-h8 mb-0 --text-dark">CATALOGUES</h6>
                </div>
              </router-link>
            </div>
            <div class="overlay-text d-flex align-items-center mb-3">
              <router-link
                to="/distribution"
                class="text-dark text-decoration-none"
              >
                <div class="d-flex">
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt="Group 55"
                    class="arrow"
                  />
                  <h6 class="s-h8 mb-0 --text-dark">DISTRIBUTION PARTNERS</h6>
                </div>
              </router-link>
            </div>
            <!-- <div class="overlay-text d-flex align-items-center mb-3">
              <router-link
                to="/bookseller"
                class="text-dark text-decoration-none"
              >
                <div class="d-flex">
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt="Group 55"
                    class="arrow"
                  />
                  <h6 class="s-h8 mb-0 --text-dark">BOOKSELLER INFORMATION</h6>
                </div>
              </router-link>
            </div> -->
            <div class="overlay-text d-flex align-items-center mb-3">
              <router-link
                to="/contact-us"
                class="text-dark text-decoration-none"
              >
                <div class="d-flex">
                  <img
                    src="/static/css/images/affirm/Group55.svg"
                    alt="Group 55"
                    class="arrow"
                  />
                  <h6 class="s-h8 mb-0 --text-dark">CONTACT US</h6>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </vue-over-body>
  </div>
</template>

<script>
import layoutMixin from "../mixins/Layout";
import SearchBar from "../components/SearchBar";
import HeaderMenu from "../components/HeaderMenu";
import overBody from "vue-over-body";

export default {
  name: "affirm-header",
  mixins: [layoutMixin],
  components: {
    SearchBar,
    HeaderMenu,
    "vue-over-body": overBody,
  },
  data() {
    return {
      index: 1,
      modal: 0,
      sidebar: 0,
      notification: 0,
      dim: true,
      search_query:'',
      searchForm: {
        search: null,
      },
    };
  },
  mounted() {
  this.$nextTick(() => {
    let path = this.$route.path;
    let ele_ment = document.getElementById("menu_btn");
    let app_ele = document.getElementById("st-pusher");
    let isbn = path.split("/")[4];

    if (!ele_ment) {
      console.warn("menu_btn element not found");
      return;
    }
    if (!app_ele && path.includes("bookseller")) {
      console.warn("st-pusher element not found");
      return;
    }

    switch (path) {
      case "/authors/":
        ele_ment.classList.add("-bg-brown");
        break;
      case "/about/":
      case "/login":
      case "/signup":
      case "/catalogues":
        ele_ment.classList.add("-bg-green");
        break;
      case "/submissions":
        ele_ment.classList.add("-bg-dark-pink");
        break;
      case `/browse/books/bookseller/${isbn}`:
        if (app_ele) app_ele.classList.add("-bg-dark-pink");
        break;
      default:
        ele_ment.classList.add("bg-white");
    }
  });
  },
  methods: {
    showSidebar: function () {
      this.$data.sidebar = this.$data.index;
      this.$data.index += 2;
    },
    openDropdown: function(){
        if(this.search_query.length > 0 && this.search_results.length > 0){
          this.show_dropdown = true;
        }
      },
    search_terms: function(){
        let search_query = this.search_query;
        if (!search_query) {
          return false;
        }
        this.search_query = '';
        this.search_results = [];

        this.$router.push({path: `/search?keyword=${encodeURI(search_query)}&page=1`});
      },
    searchData() {
        var x = document.getElementById("searchinput");
        if (x.style.display === "none") {
          x.style.display = "block";
          $('.search-input').focus();
        } else {
          x.style.display = "none";
        }
      },
  },
  computed: {},
};
</script>

<style>
/* header-sidebar */
.main-sidebar .minus-icon {
  position: absolute;
  right: 9%;
  top: 44px;
  cursor: pointer;
}
.main-sidebar .minus-shape {
  width: 20px;
  height: 4.5px;
  display: inline-block;
  border-radius: 5px;
  background: #202529;
}
.over_body_mask_after {
  background-color: #c1cbc4cf;
}
.sideAfter {
  right: 0 !important;
  left: unset;
  opacity: 0;
}
.sideBefore {
  height: 100%;
  width: 36%;
  min-width: 320px;
  top: 0;
  opacity: 1;
  margin: auto 0 auto auto;
  box-shadow: 0px 0px 60px #0000004d;
  right: 0px;
  left: unset;
}
.search-box{
  display: none;
}
@media only screen and (min-width: 300px) and (max-width: 365px) {
  .search-box{
    display: block;
    left: 27px;
    width: 126px;
    position: relative;
  }
  .input-search{
    height: 50px;
    width: 50px;
    border-style: none;
    padding: 0.75em 1.5em !important;
    font-size: 18px;
    letter-spacing: 2px;
    outline: none;
    border-radius: 25px;
    transition: all .5s ease-in-out;
    /* padding-right: 40px; */
    color: black;
    border-style: none !important;
  }

  .btn-search{
    width: 50px;
    height: 50px;
    border-style: none;
    font-size: 20px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    position: absolute;
    right: 0px;
    color:black ;
    background-color:#ffff;
    pointer-events: painted;  
  }
  .btn-search:focus ~ .input-search{
    width: 300px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom:1px solid rgba(255,255,255,.5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  }
  .input-search:focus{
    width: 300px;
    border-radius: 0px;
    background-color: #f8f9fa;
    border-bottom:1px solid rgba(255,255,255,.5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  }
  .ms-4 {
    margin-left: 0 !important;
}
.toggle-menu-icon {
    top: 47px;
    right: auto;
    z-index: 999;
    right: 6px;
}
}

/* @media only screen and (max-width: 375px) {
  .search-box{
    display: block;
    left: 27px;
    width: 126px;
    position: relative;
  }
  .ms-4 {
    margin-left: 0 !important;
}
.toggle-menu-icon {
    top: 47px;
    right: auto;
    z-index: 999;
    right: 6px;
}
} */
@media only screen and (min-width: 320px) and (max-width: 568px) {
  /* Styles for iPhone SE and similar devices */
  /* Add your CSS rules here */
  .search-box{
    display: block;
    left: 27px !important;
    width: 126px !important;
    position: relative !important;;
  }
  .ms-4 {
    margin-left: 0 !important;
}
.toggle-menu-icon {
    top: 47px;
    right: auto;
    z-index: 999;
    right: 6px;
}
}

@media only screen and (min-width: 375px) and (max-width: 767px) {
  .search-box{
    display: block;
    left: 16px;
    width: 154px;
    position: relative;
  }
  .input-search{
    height: 50px;
    width: 50px;
    border-style: none;
    padding: 0.75em 1.5em !important;
    font-size: 18px;
    letter-spacing: 2px;
    outline: none;
    border-radius: 25px;
    transition: all .5s ease-in-out;
    /* padding-right: 40px; */
    color: black;
    border-style: none !important;
  }

  .btn-search{
    width: 50px;
    height: 50px;
    border-style: none;
    font-size: 20px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    position: absolute;
    right: 0px;
    color:black ;
    background-color:#ffff;
    pointer-events: painted;  
  }
  .btn-search:focus ~ .input-search{
    width: 300px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom:1px solid rgba(255,255,255,.5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  }
  .input-search:focus{
    width: 300px;
    border-radius: 0px;
    background-color: #f8f9fa;
    border-bottom:1px solid rgba(255,255,255,.5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  }
  .ms-4 {
    margin-left: 0rem !important;
}
.position-fixed {
    position: fixed !important;
    right: 7px;
}
}
@media only screen and (min-width: 768px) and (max-width: 900px) {
  .search-box{
    display: block;
    left: 20px;
    width: 502px;
    position: relative;
  }
  .input-search{
    height: 50px;
    width: 50px;
    border-style: none;
    padding: 0.75em 1.5em !important;
    font-size: 18px;
    letter-spacing: 2px;
    outline: none;
    border-radius: 25px;
    transition: all .5s ease-in-out;
    /* padding-right: 40px; */
    color: black;
    border-style: none !important;
  }

  .btn-search{
    width: 50px;
    height: 50px;
    border-style: none;
    font-size: 20px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    position: absolute;
    right: 0px;
    color:black ;
    background-color:#ffff;
    pointer-events: painted;  
  }
  .btn-search:focus ~ .input-search{
    width: 300px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom:1px solid rgba(255,255,255,.5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  }
  .input-search:focus{
    width: 300px;
    border-radius: 0px;
    background-color: #f8f9fa;
    border-bottom:1px solid rgba(255,255,255,.5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  }
}



</style>
