<template>
  <footer class="l-footer mobileFullWidthWithPadding">
    <div class="wrapper">
      <div class="moduletable footer-menu">
          <footer-menu></footer-menu>
      </div>
      <div class="moduletable">
        <div class="custom">
          <p>&nbsp;</p>
        </div>
      </div>
      <div v-if="host == 'allenandunwin'" class="custom social-icons mobileFullWidthWithPadding mobileHide"  >
        <p>
          <a class="icon-f logo-icon" href="https://www.facebook.com/AllenandUnwinBooks" target="_blank">
            <span>Facebook</span>
          </a> 
          <a class="icon-t logo-icon" href="https://twitter.com/AllenAndUnwin" target="_blank">
            <span>Twitter</span>
          </a>
          <a class="icon-i logo-icon" href="http://instagram.com/allenandunwin" target="_blank">
            <span>Instegram</span>
          </a> 
        </p>
      </div>
      <div v-else="host == 'allenandunwinnz'" class="custom social-icons mobileFullWidthWithPadding mobileHide"  >
        <p>
          <a class="icon-f" href="https://www.facebook.com/AllenAndUnwinNZ" target="_blank">
            <span>Facebook</span>
          </a> 
          <a class="icon-t" href="https://twitter.com/AllenAndUnwinNZ" target="_blank">
            <span>Twitter</span>
          </a>  
          <a class="icon-i" href="https://www.instagram.com/allenandunwinnz/" target="_blank">
            <span>Instegram</span>
          </a> 
        </p>
      </div>       
      <router-link
        class="logo"
        to="/"
        v-if="host == 'allenandunwin'"
      >
        <img alt="Logo" src="/static/logo_au.png" />
      </router-link>
       <router-link
        class="logo"
        to="/"
        v-else="host == 'allenandunwinnz'"
      >
        <img alt="Logo" src="/static/AotearoaNZ_White.png" />
      </router-link>
      <router-link class="logo"
      to="/"
      v-else="host == 'allenandunwinnz'">
      <img alt="Logo" src="/static/AotearoaNZ_White.png" class="footer_logo01" />
    </router-link>
      <div class="clear"></div>
    </div>
  </footer>
</template>

<script v-if="host == 'allenandunwinnz'" async src="https://www.googletagmanager.com/gtag/js?id=UA-44999566-2"> </script>

<script v-if="host == 'allenandunwinnz'">
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'UA-44999566-2');
</script>
<script>
import layoutMixin from "../mixins/Layout";
import FooterMenu from '../components/FooterMenu';


export default {
    name: 'au-footer',
    mixins: [layoutMixin],
    components: {FooterMenu}
};
</script>

<style>
</style>