<template>
  <div>
    <affirm-author-feature></affirm-author-feature>
    <!-- author tabs -->
    <section class="mb-5">
      <div class="st-container">
        <div class="row">
          <div class="col-12 col-lg-12 col-xl-12">
            <div>
              <ul class="d-inline-block p-0 tabs">
                <li class="d-inline-block me-3 mb-3 mb-lg-0">
                  <a
                    class="pb-2 s-h6 --text-dark active text-decoration-none"
                    aria-current="page"
                    >All Authors & Illustrators</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('a')"
                    :class="{ active: active === 'a' }"
                    >A</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('b')"
                    :class="{ active: active === 'b' }"
                    >B</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('c')"
                    :class="{ active: active === 'c' }"
                    >C</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('d')"
                    :class="{ active: active === 'd' }"
                    >D</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('e')"
                    :class="{ active: active === 'e' }"
                    >E</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('f')"
                    :class="{ active: active === 'f' }"
                    >F</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('g')"
                    :class="{ active: active === 'g' }"
                    >G</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('h')"
                    :class="{ active: active === 'h' }"
                    >H</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('i')"
                    :class="{ active: active === 'i' }"
                    >I</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('j')"
                    :class="{ active: active === 'j' }"
                    >J</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('k')"
                    :class="{ active: active === 'k' }"
                    >K</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('l')"
                    :class="{ active: active === 'l' }"
                    >L</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('m')"
                    :class="{ active: active === 'm' }"
                    >M</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('n')"
                    :class="{ active: active === 'n' }"
                    >N</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('o')"
                    :class="{ active: active === 'o' }"
                    >O</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('p')"
                    :class="{ active: active === 'p' }"
                    >P</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('q')"
                    :class="{ active: active === 'q' }"
                    >Q</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('r')"
                    :class="{ active: active === 'r' }"
                    >R</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('s')"
                    :class="{ active: active === 's' }"
                    >S</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('t')"
                    :class="{ active: active === 't' }"
                    >T</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('u')"
                    :class="{ active: active === 'u' }"
                    >U</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('v')"
                    :class="{ active: active === 'v' }"
                    >V</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('w')"
                    :class="{ active: active === 'w' }"
                    >W</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('x')"
                    :class="{ active: active === 'x' }"
                    >X</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('y')"
                    :class="{ active: active === 'y' }"
                    >Y</a
                  >
                </li>
                <li class="d-inline-block me-2 mb-3 mb-lg-0">
                  <a
                    class="link pb-2 s-h6 --text-dark text-decoration-none"
                    v-on:click="makeActive('z')"
                    :class="{ active: active === 'z' }"
                    >Z</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-lg-12 col-xl-12">
            <p class="--text-dark s-h6 mt-2 mb-0">
              Please search by author surname
            </p>
            <!-- <div class="ME-2">
              <form class="d-flex tab-search">
                <input
                  class="form-control custom-input rounded-pill border-0 -h-30"
                  type="search"
                  placeholder="SEARCH AUTHOR"
                  aria-label="Search"
                />
                <div class="position-absolute tab-search-icon">
                  <i class="fa fa-search"></i>
                </div>
              </form>
            </div> -->
            <!-- <div>
              <p class="s-h6">468 results</p>
            </div> -->
          </div>
          <div class="col-12 mt-3 mt-lg-4">
            <div
              class="d-block d-sm-flex align-items-center justify-content-between"
            >
              <div>
                <div class="dropdown mb-3 mb-sm-0">
                  <select
                    name="sorts_authors"
                    class="--sort-authors"
                    id=""
                    @change="getSort($event)"
                  >
                    <option v-for="sort in sorts" v-bind:value="sort.code">
                      {{ sort.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section
          class="mb-5"
          v-if="authors.length > 0 && authors != 'no record found'"
        >
          <looping-rhombuses-spinner
            :animation-duration="2500"
            :rhombus-size="15"
            color="#febe10"
            v-if="authors.length == 0"
            class="loader-spinner"
          />
          <div class="">
            <div class="row">
              <div class="col-12">
                <div class="author-grid mt-3">
                  <div class="my-3" v-for="author in authors" :key="author.id">
                    <div
                      class="img-card overflow-hidden rounded-circle border author-img_box mx-auto d-flex align-items-center justify-content-center"
                    >
                      <div class="inner-img text-center">
                        <router-link
                          :to="{ path: '/author/' + author.idauthor }"
                        >
                          <img
                            v-if="author.author_image"
                            v-bind:src="author.author_image"
                            alt=""
                            style="width: 100%"
                            class="transition"
                            @error="noCoverImage"
                          />
                          <img
                            v-else
                            v-bind:src="noCoverImage"
                            alt=""
                            style="width: 100%"
                            class="transition"
                            @error="noCoverImage"
                          />
                        </router-link>
                      </div>
                    </div>
                    <div class="mt-2">
                      <h5 class="fw-normal text-center s-h7 mb-1">
                        {{ author.firstname }} {{ author.lastname }}
                      </h5>
                    </div>
                    <div class="text-center">
                      <router-link :to="{ path: '/author/' + author.idauthor }">
                        <img
                          src="/static/css/images/affirm/iconmonstr-arrow-right-thin.png"
                          alt="arrow"
                          width="24px"
                        />
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="shouldShowLoadMoreButton" class="row" @click="author_page_data(active)">
            <div class="col-12">
              <div class="link --text-dark">
                <div class="text-center mt-5 cursor-pointer">
                  <p class="--text-dark text-center mb-2">Load more</p>
                  <div class="text-center">
                    <img
                      src="/static/css/images/affirm/iconmonstr-arrow-right-thin.png"
                      alt="arrow"
                      width="24px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          v-else-if="authors == 'no record found' && active != 'all_author'"
        >
          <p class="text-center --text-dark">no record found</p>
        </section>
        <affirm-author-sort
          v-bind="{ sort_by: selected }"
          v-else-if="
            selected != 'All' && selected != 'alph' && active == 'all_author'
          "
        ></affirm-author-sort>
        <affirm-author-alb-sort
          v-bind="{ sort_by: selected }"
          v-else-if="selected != 'All' && active == 'all_author'"
        ></affirm-author-alb-sort>

        <affirm-author-page
          v-bind="{ active_menu: authors }"
          v-else
        ></affirm-author-page>
      </div>
      <div></div>
    </section>
    <!-- author tabs -->
  </div>
</template>
<script>
import axios from "axios";
import AffirmAuthorsPages from "../components/AffirmAuthorsPages";
import AffirmAuthorFeature from "../components/AffirmAuthorFeature";
import AffirmSort from "../components/AffirmSort";
import AffirmsortAlphabetical from "../components/AffirmsortAlphabetical";
import { LoopingRhombusesSpinner } from "epic-spinners";
export default {
  name: "affirm-authors",
  components: {
    "affirm-author-page": AffirmAuthorsPages,
    "affirm-author-feature": AffirmAuthorFeature,
    "affirm-author-sort": AffirmSort,
    "affirm-author-alb-sort": AffirmsortAlphabetical,
    LoopingRhombusesSpinner,
  },
  data() {
    return {
      active: "all_author",
      authors: [],
      test: [],
      selected: "All",
      sorts: [
        {
          name: "--Any--",
          code: "All",
        },
        {
          name: "Sort by Latest",
          code: "sort_dsc",
        },
        {
          name: "Alphabetical",
          code: "alph",
        },
      ],
      page: 1,
      load_page: 2,
    };
  },
  methods: {
    noCoverImage: function (event) {
      event.target.src =
        "/static/css/images/affirm/author_photo_coming_soon.jpg";
    },
    getSort: function (code) {
      this.selected = code.target.value;
    },
    author_page_data: function (active) {
      let self = this;
      axios
        .get(`/get_author_data/?sort_name=${active}&page=${self.load_page}`)
        .then(function (data) {
          self.test = data.data.authors;
          if (self.load_page > 1) {
            for (let author of self.test) {
              self.authors.push(author);
            }
          } else {
            self.authors = self.test;
          }
          self.load_page++;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    makeActive: function (item) {
      this.active = item;
      let self = this;
      axios
        .get(`/get_author_data/?sort_name=${self.active}&page=${self.page}`)
        .then(function (data) {
          self.authors = data.data.authors;
          self.load_page = 2;
          if (self.authors.length == 0) {
            self.authors = "no record found";
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  },
  computed: {
    shouldShowLoadMoreButton() {
      return this.authors.length > 10;
    }

  },
  watch: {},
  mounted() {
  let add_new_class = document.getElementById("affirm-header");
  let remode_class = document.getElementById("for-affirm");

  if (add_new_class) {
    add_new_class.classList.add("-bg-dark-pink");
  } else {
    console.warn("Element with ID 'affirm-header' not found");
  }

  if (remode_class) {
    remode_class.classList.remove("container");
  } else {
    console.warn("Element with ID 'for-affirm' not found");
  }

  this.author_page_data(this.active);
}

};
</script>
<style>
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: block;
}
.--sort-authors {
  border: 0;
  background: unset;
  min-width: 150px;
}
.--sort-authors:focus-visible {
  outline: none !important;
}
</style>
