import Vue from 'vue'
import App from './App.vue'
import router_au from './router/index_au.js'
import router_acc from './router/index_acc.js'
import router_affirm from './router/index_affirm.js'
import store from './store'

import Default from './layouts/Default.vue'
import Contents from './layouts/Contents.vue'
import DefaultMurdoch from './layouts/murdoch/DefaultMurdoch.vue'
import ContentsMurdoch from './layouts/murdoch/ContentsMurdoch.vue'
import DefaultAcc from './layouts/ACC/defaultacc.vue'
import ContentAcc from './layouts/ACC/Contentacc.vue'
import DefaultAffirm from './layouts/affirm/DefaultAffirm.vue'
import ContentsAffirm from './layouts/affirm/ContentsAffirm.vue'
import VModal from 'vue-js-modal'
import VueMeta from 'vue-meta'
import utils from './utils.js'
import VueClazyLoad from 'vue-clazy-load';

Vue.config.productionTip = false
Vue.config.devtools = false

Vue.component('default-layout', Default);
Vue.component('contents-layout', Contents);
Vue.component('default-murdoch-layout', DefaultMurdoch);
Vue.component('contents-murdoch-layout', ContentsMurdoch);
Vue.component('default-acc-layout', DefaultAcc);
Vue.component('contents-acc-layout', ContentAcc);
Vue.component('default-affirm-layout', DefaultAffirm);
Vue.component('contents-affirm-layout', ContentsAffirm);
// Vue.use(VModal, { dialog: true });
Vue.use(VModal, { dynamic: true, injectModalsContainer: true });
Vue.use(VueMeta);
Vue.use(VueClazyLoad);

let host = utils.resolve_host(window.location.hostname);

let router = null;
if (host == 'acc'){
  router = router_acc;
}
else if (host == "affirm"){
  router = router_affirm;
}
else{
  router = router_au;
}


new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

