import axios from "axios";

export default {
    data() {
        return {
            newsletterEmail: null,
            screen_loaded: false,
            list_id: null,
            campaign_id: null,
            custom_fields: []
        }
    },
    methods: {
        goToSubscribePage() {
            let email_input = document.getElementById('subscribe-to-newsletter');
            email_input.reportValidity();
            if (!this.newsletterEmail || !email_input.checkValidity()) {
                return;
            }
            this.$router.push({
                name: 'newsletters',
                params: {
                    email: this.newsletterEmail
                }
            })
        }
    },
    computed:{
        get_custom_fields_data(){
            let checkboxes = [];
            if(this.custom_fields && this.custom_fields.length > 0){
                for (let i = 0; i < this.custom_fields.length; i++) {
                    const field = this.custom_fields[i];
                    if(field.DataType == 'MultiSelectMany'){
                        checkboxes.push(field);
                    }
                }
            }
            return checkboxes;
        }
    },
    mounted() {
        let self = this;
        if (this.$route.name == 'newsletters') {
            // do axios request here to post email
            if (this.$route.params.email) {
                this.newsletterEmail = this.$route.params.email;
                axios
                    .post('/register_newsletter_email/', {
                        params: {
                            email: this.newsletterEmail
                        }
                    }, {
                        headers: {
                            'X-CSRFTOKEN': csrfToken
                        }
                    }).then(function (res) {
                        console.log(res);
                    }).catch(function (err) {
                        console.log(err);
                    });
            }
            window.scrollTo(0, 0);
        }

        if (!self.screen_loaded) {
            axios.get('/get_newsletter_form_data/', {
                params: {
                    page: this.$route.path
                }
            }).then(function(res) {
                console.log(res);
                self.screen_loaded = true;
                self.list_id = res.data.list_id;
                self.custom_fields = res.data.custom_fields;
                self.campaign_id = res.data.campaign_id;
            }).catch(function(err){
                console.log(err);
            });
        }
    }
}